import {AiOutlineLinkedin,AiOutlineGithub} from 'react-icons/ai'
const HeaderSocials = () => {
  return (
    <div className="header_socials">
        <a href="https://www.linkedin.com/in/haowei-lou-0088201b0/" target="__blank"><AiOutlineLinkedin /></a>
        <a href="https://github.com/haoweilou" target="__blank"><AiOutlineGithub /></a>
    </div>
  )
}

export default HeaderSocials