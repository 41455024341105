import React from 'react'
import About from './components/about/about'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import Experience from './components/experience/experience'
import Publication from './components/publication/publication'
import Contact from './components/contact/contact'
import Footer from './components/footer/footer'
import Award from './components/award/award'

const App = () => {
  return (
    <>
      <Header />
      <Nav />
      <About />
      {/* <Experience /> */}
      <Publication />
      <Award />
      <Contact />
      <Footer />
    </>
  )
}

export default App