import React from 'react'
import './award.css'
import award_card from './award_card'
const award = () => {
    const awardData = require('../../database/award.json')
    var award_components = [];
    awardData['awards'].forEach(element => {
        award_components.push(award_card(element))
    });
    return (
        <section id='award'>
            
            <h2>My Awards</h2>
            <div className='papers_container'>
                {award_components}
            </div>
            
        </section>
    )
}

export default award