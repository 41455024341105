import React from 'react'
import parse from 'html-react-parser'

const publication_card = (element) => {
    var title = element['title'];
    var author = element['author'].replaceAll(",","").replaceAll("and",", ");
    author = author.replace("Lou Haowei", "<span class='haowei'>Lou Haowei</span>")
    var journel = element['journal_short']+element['year'];
    var link = element['link'];
    return (
        <div className='publication_card'>
            <a href={link} target="_blank">
                <div className='paper_title'>{title}</div> 
            </a>
            <div className='paper_author'>{parse(author,)}</div>
            <div className='paper_journel'>{journel}</div>
        </div>
    )
}

export default publication_card