import React from 'react'
import './nav.css'
import {AiOutlineHome,AiOutlineMail} from 'react-icons/ai'
import {BiBook, BiUserCircle} from 'react-icons/bi'
import {MdWork} from 'react-icons/md'
import {useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')}  className={activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <BiUserCircle />
      </a>
      <a href="#publication" onClick={() => setActiveNav('#publication')} className={activeNav === '#publication' ? 'active' : ''}>
        <BiBook />
      </a>
      
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <AiOutlineMail />
      </a>
    </nav>
  )
}

export default Nav