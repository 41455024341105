import React from 'react'
import publication_card from './publication_card';
import './publication.css'
const publication = () => {
    const paperData = require('../../database/paper.json')
    const papersByYear = {};

    var paper_components = [];
    // Group papers by year
    paperData['papers'].forEach(element => {
        const year = element.year; // Assuming each element has a 'year' field
        if (!papersByYear[year]) {
            papersByYear[year] = [];
        }
        papersByYear[year].push(element);
    });
    

    // Generate paper components grouped by year
    const paperComponents = [];
    Object.keys(papersByYear).sort((a, b) => b - a).forEach(year => {
        paperComponents.push(
            <div key={year} className='publication_year'>
                <h3>{year}</h3> {/* Subtitle for the year */}
                {papersByYear[year].map((paper, index) => publication_card(paper))}
            </div>
        );
    });

    paperData['papers'].forEach(element => {
        paper_components.push(publication_card(element))
    });
    return (
        <section id='publication'>
            <h5>Work I Have</h5>
            <h2>My Publications</h2>
            <div className='papers_container'>
                {paperComponents}
            </div>
        
        </section>
    )
}

export default publication