import React from 'react'
import './footer.css'
import {BsFacebook,BsInstagram} from 'react-icons/bs'
const footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'></a>
      <ul className='permalink'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer_socials'>
        <a href="https://www.facebook.com/haowei.lou.7/"><BsFacebook /></a>
        <a href="https://www.instagram.com/haowei.lou/"><BsInstagram /></a>
      </div>

      <div className='footer_copyright'>
        <small>&copy; All rights reserved.</small>
      </div>
    </footer>
  )
}

export default footer