import React from 'react'
import './about.css'
import ME from '../../assets/haowei.png' 
import {BsCodeSlash} from 'react-icons/bs'
import {AiTwotoneExperiment} from 'react-icons/ai'
import {FaGraduationCap} from 'react-icons/fa'
const About = () => {
  return (
    <section id='about'>
      <h5>Get To Konw</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me_img">

            <img src={ME} alt="about" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <BsCodeSlash className='about_icon'/>
              <h5>Software Development</h5>
              <small>6 years</small>
            </article>

            <article className='about_card'>
              <AiTwotoneExperiment className='about_icon'/>
              <h5>Research</h5>
              <small>3 years</small>
            </article>

            <article className='about_card'>
            
              <FaGraduationCap className='about_icon'/>
              <h5>Teaching</h5>
              <small>4 years</small>
            </article>
          </div>
          <p>
          I am a PhD candidate specializing in Generative Artificial Intelligence, Brain-Computer Interfaces, and Speech Generation. My research focuses on advancing the capabilities of AI in creating natural and expressive human-like interactions, bridging the gap between human and machine communication. Alongside my research, I mentor the Computer Science Project course at UNSW, where I mentor students in software system development, teamwork, and effective communication.
          </p>
          <a href="#contact" className='btn btn_primary'>Let's talk</a>
        </div>
      </div>


    </section>
  )
}

export default About