import React from 'react'
const award_card = (element) => {
    var name = element['name'];
    var year = element['year']
  return (
    <div className='award_card'>
        <div className='award_name'>{name}</div> 
        <div className='award_year'>{year}</div>
    </div>
  )
}

export default award_card