import './header'
import CTA from './CTA'
import ME from '../../assets/haoweiface.png'
import HeaderSocials from './HeaderSocials'
import './header.css'
const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>Haowei Lou</h1>
        {/* <h5 className="text_light">Fullstack Developer | Artificial Intelligence Researcher | Creative Artist</h5> */}
        {/* <CTA /> */}
        <div className="me">
          <img src={ME} alt="" />
        </div>

        <a href="#contact" className='scroll_down'>Scroll Down</a>
        <HeaderSocials />
      </div>
    </header>
  )
}

export default Header